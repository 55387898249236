body {
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, #root {
  height: 100vh;
}
* {
  /* scrollbar-color: rgb(70, 70, 70) transparent; */
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
}
 
*::-webkit-scrollbar-track {
  background: transparent;
}
 
*::-webkit-scrollbar-thumb {
  /* background-color: rgba(0, 0, 0, 0.1); */
  border-radius: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: underline;
  color: black;
}

table {
  border-spacing: 0
}

tr:nth-child(even) {
  background: rgba(255,255,255,0.05);
  border-radius: 8px;
}

a:hover {
  text-decoration: none;
}

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";
